/** Import react/libraries section **/
import React, { useState, useLayoutEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

/** Import helpers section **/
import { setMenuOpen } from '../../store/slices/globals-slice';

/** Import styles section **/

/** Import resources section **/
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';

/** Import component section **/
import HeaderComponent from '../Header';
import MenuComponent from '../Menu';
import PrivateContent from '../PrivateContent';

const drawerWidth = 220;
const windowWidthMenuClose = 1000;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex'
	},
	appBar: {
		backgroundColor: theme.palette.secondary.main,
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		borderRadius: 0
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	menuButton: {
		marginLeft: -10,
		marginRight: 36
	},
	hide: {
		display: 'none'
	},
	hideButton: {
		color: 'white'
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
		color: 'red'
	},
	drawerOpen: {
		backgroundColor: theme.palette.secondary.main,
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		borderRadius: 0
	},
	drawerClose: {
		backgroundColor: theme.palette.secondary.main,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9) + 1
		},
		borderRadius: 0
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	}
}));

const PrivateLayoutComponent = () => {
	const classes = useStyles();
	const theme = useTheme();
	const [open, setOpen] = useState(false);

	const dispatch = useDispatch();

	const handleDrawerOpen = useCallback(() => {
		setOpen(true);
		dispatch(setMenuOpen({ open: true }));
	}, [dispatch]);

	const handleDrawerClose = useCallback(() => {
		setOpen(false);
		dispatch(setMenuOpen({ open: false }));
	}, [dispatch]);

	useLayoutEffect(() => {
		function closeOnSizeUpdate() {
			// console.log(window.innerWidth);
			if (window.innerWidth < windowWidthMenuClose) {
				handleDrawerClose();
			} else {
				handleDrawerOpen();
			}
		}
		window.addEventListener('resize', closeOnSizeUpdate);
		closeOnSizeUpdate();
		return () => window.removeEventListener('resize', closeOnSizeUpdate);
	}, [handleDrawerClose, handleDrawerOpen]);

	return (
		<div className={classes.root}>
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open
				})}
			>
				<Toolbar className="privatecontainer_toolbar">
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(classes.menuButton, {
							[classes.hide]: open
						})}
					>
						<MenuIcon />
					</IconButton>
					<HeaderComponent />
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open
					})
				}}
			>
				<div className={classes.toolbar}>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? (
							<ChevronRightIcon />
						) : (
							<ChevronLeftIcon className={classes.hideButton} />
						)}
					</IconButton>
				</div>
				<Divider />
				<MenuComponent />
			</Drawer>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				<PrivateContent />
			</main>
		</div>
	);
};

export default PrivateLayoutComponent;
