/*********************************************************************
 ***********************  ROUTES DEFINITION  *************************
 *********************************************************************/

// Public routes
export const LOGIN_ROUTE = '/login';
export const REGISTRATION_ROUTE = '/registration';
export const EMAIL_VALIDATION_ROUTE = '/register-verify';
export const CHANGE_PASSWORD_REQUEST_ROUTE = '/change-password-request';
export const CHANGE_PASSWORD_ROUTE = '/change-password';

// Private routes
export const USERS_ROUTE = '/users';
export const ROLE_PERMISSIONS_ROUTE = '/role-permissions';
export const PERMISSIONS_ROUTE = '/permissions-catalogue';
export const CERTIFICATES_ROUTE = '/certificates';

// export const ROLE_PERMISSIONS_ROUTE = '/rol-permisos';
// export const PERMISSIONS_ROUTE = '/permisos';
