/** Import helpers section **/
import * as routes from './URL_ROUTES';

/** Import component section **/
import LoginComponent from '../views/Login';
import RegistrationComponent from '../views/Registration';
import EmailValidationComponent from '../views/EmailValidation';
import ChangePasswordRequestComponent from '../views/ChangePasswordRequest';
import ChangePasswordComponent from '../views/ChangePassword';

import UsersComponent from '../views/Users';
import PermissionsComponent from '../views/Permissions';
import RolePermisionsComponent from '../views/RolePermissions';
import CertificatesComponent from '../views/Certificates';

/*********************************************************************
 ************************  ROUTES CONTENT  ***************************
 *********************************************************************/

export const PUBLIC_ROUTES = [
	{
		path: '/',
		component: LoginComponent
	},
	{
		path: routes.LOGIN_ROUTE,
		component: LoginComponent
	},
	{
		path: routes.REGISTRATION_ROUTE,
		component: RegistrationComponent
	},
	{
		path: routes.EMAIL_VALIDATION_ROUTE + '/:token',
		component: EmailValidationComponent
	},
	{
		path: routes.CHANGE_PASSWORD_REQUEST_ROUTE,
		component: ChangePasswordRequestComponent
	},
	{
		path: routes.CHANGE_PASSWORD_ROUTE + '/:token',
		component: ChangePasswordComponent
	}
];

export const PRIVATE_ROUTES = [
	{
		path: routes.USERS_ROUTE,
		component: UsersComponent
	},
	{
		path: routes.CERTIFICATES_ROUTE,
		component: CertificatesComponent
	},
	{
		path: '/',
		component: UsersComponent
	},
	{
		path: routes.PERMISSIONS_ROUTE,
		component: PermissionsComponent
	},
	{
		path: routes.ROLE_PERMISSIONS_ROUTE,
		component: RolePermisionsComponent
	}
];
