/** Import react/libraries section **/
import axios from 'axios';

/** Import helpers section **/
import { toast } from 'react-toastify';
import { LOCAL_STORAGE_PREFIX } from '../config/APP_CONFIGS';

const localStoragePrefix = LOCAL_STORAGE_PREFIX
	? LOCAL_STORAGE_PREFIX + '@'
	: '';

/** Constants **/
const API_URL = process.env.REACT_APP_BACK_URL + 'api/';
const DEFAULT_ERROR_RESPONSE = { response_status: 0 };
const unAuthorizedEvent = new CustomEvent('unauthorized');

// *** request *** //
// *** Función para realizar una petición al server *** //
// *** Recibe un string con el código definido de una servicio y un objeto de parámetros *** //
// *** Regresa un objeto con la respuesta exitosa o fallida *** //
// *** En caso de respuesta exitosa {data: <objeto de información>} *** //
// *** En caso de respuesta fallida {response_status: <entero de status>} *** //
export default function request({ url, method, params }) {
	const apiProvider = axios.create({
		baseURL: API_URL,
		crossDomain: true,
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Content-Type': 'application/json'
		}
	});

	const token = localStorage.getItem(localStoragePrefix + 'token');

	if (token) {
		apiProvider.defaults.headers.common['Authorization'] = 'Bearer ' + token;
	}

	const requestParams = params ? params : {};
	/*
	if (method === 'file') {
		apiProvider.defaults.headers.post['Content-Type'] = 'multipart/form-data';
	}

	if (method === 'get') {
		//get
		let response = apiProvider
			.get(API_URL + url, {
				params: requestParams
			})
			.then((res) => requestSuccess(res))
			.catch((err) => requestFailure(err));
		return response;
	} 
  
  if (method === 'post') {
		//post
		let response = apiProvider
			.post(API_URL + url, params)
			.then((res) => requestSuccess(res))
			.catch((err) => requestFailure(err));
		return response;
	} 
  
  if (method === 'put') {
		//post
		let response = apiProvider
			.put(API_URL + url, params)
			.then((res) => requestSuccess(res))
			.catch((err) => requestFailure(err));
		return response;
	}
  */
	switch (method) {
		case 'file':
			apiProvider.defaults.headers.post['Content-Type'] = 'multipart/form-data';
			break;
		case 'get': {
			let response = apiProvider
				.get(API_URL + url, {
					params: requestParams
				})
				.then((res) => requestSuccess(res))
				.catch((err) => requestFailure(err));
			return response;
		}
		case 'post': {
			let response = apiProvider
				.post(API_URL + url, params)
				.then((res) => requestSuccess(res))
				.catch((err) => requestFailure(err));
			return response;
		}
		case 'put': {
			let response = apiProvider
				.put(API_URL + url, params)
				.then((res) => requestSuccess(res))
				.catch((err) => requestFailure(err));
			return response;
		}
		case 'delete': {
			let data = { params };
			let response = apiProvider
				.delete(API_URL + url, data)
				.then((res) => requestSuccess(res))
				.catch((err) => requestFailure(err));
			return response;
		}
		default:
			break;
	}
}

// Métodos de respuesta

export function requestSuccess(res) {
	if (res.hasOwnProperty('data') && res.data.hasOwnProperty('data')) {
		return Promise.resolve(res.data.data);
	}
	APIFailure();
	// return Promise.reject(DEFAULT_ERROR_RESPONSE);
}

export function requestFailure(err) {
	err = Object.assign({}, err);

	if (typeof err !== 'object') {
		APIFailure();
	}

	if (!err.hasOwnProperty('response') || !err.response) {
		APIFailure();
	}

	if (!err.response.hasOwnProperty('status') || !err.response.status) {
		APIFailure();
	}

	const STATUS = err.response.status;

	if (STATUS === 401) {
		document.dispatchEvent(unAuthorizedEvent);
	}

	if (STATUS === 500 || STATUS === 404) {
		toast.error('An system error occurred');
		return Promise.reject(DEFAULT_ERROR_RESPONSE);
	}

	return Promise.reject({ response_status: STATUS }); // regresa el status y el listado de errores
}

function APIFailure() {
	// aquí se podría mandar mensaje de error de comunicación
	toast.error('A communication error occurred');
	return Promise.reject(DEFAULT_ERROR_RESPONSE);
}
