/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';

/** Import helpers section **/
import { toast } from 'react-toastify';
import {
  //getRolesService,
  getCertificatesService,
  getCertificateByIdService,
  deleteCertificateService,
  changeActiveService,
  saveCertificateLogoService
} from '../../api/services/certificates-services';
import { getCountriesService } from '../../api/services/country-services';

/** Import resources section **/

/** Import component section **/
import Modal from '../../components/Modal';
import DropFile from '../../components/DropFile';
import CertificateModal from './CertificateModal';
import CertificatesView from './CertificatesView';

const CertificateModel = {
  id: null,
  name: '',
  certification: '',
  email: '',
  active: 1,
  address: '',
  phone: '',
  location: '',
  company: '',
  logo: '',
  instagram: '',
  facebook: '',
  country_id: ''
};

const CertificatesComponent = () => {
  //const [roles, setRoles] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  const getCountries = useCallback(() => {
    getCountriesService()
      .then((response) => {
        if (response?.countries) setCountries(response?.countries);
      })
      .catch((error) => {
        console.error('Certificates Component - Error getting: ', error);
      });
  }, []);

  const getCertificates = useCallback(() => {
    setLoading(true);
    getCertificatesService()
      .then((response) => {
        setCertificates(response?.certificates);
        setOriginalData(response?.certificates);
      })
      .catch((error) => {
        console.error(
          'Certificates Component - Error requesting Certificates list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getCountries();
    getCertificates();
  }, [getCountries, getCertificates]);

  const handleOnUpdateList = (selectedCertificate) => {
    getCertificates();
  };

  const handleOnFilterCertificates = (filterVal) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal?.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const field1 = item.first_name?.toString().toLowerCase();
        const field2 = item.last_name?.toString().toLowerCase();
        const field3 = item.email?.toString().toLowerCase();
        const field4 = item.role_title?.toString().toLowerCase();

        return (
          field1.match(filteringText) ||
          field2.match(filteringText) ||
          field3.match(filteringText) ||
          (field4.match(filteringText) && true)
        );
      });
    }

    setCertificates(filteredItems);
  };

  const handleOnAddCertificate = () => {
    setSelectedCertificate(Object.assign({}, CertificateModel));
    setModalMode('certificate');
  };

  const getCertificateById = (id, mode) => {
    getCertificateByIdService({ id })
      .then((response) => {
        setSelectedCertificate(response?.certificate);
        setModalMode(mode);
      })
      .catch((error) => {
        console.error(
          'CertificatesComp - Error fetching Certificate data: ',
          error
        );
      });
  };

  const handleOnEditCertificate = (row) => {
    getCertificateById(row.id, 'certificate');
  };

  const handleOnModalClose = () => {
    setSelectedCertificate(null);
    setModalMode(null);
  };

  const handleOnDeleteCertificate = (row) => {
    setSelectedCertificate(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteCertificateService({ id: selectedCertificate.id })
      .then(() => {
        getCertificates();
        toast.success('Certificado borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el Certificado.');
      })
      .finally(() => {
        setSelectedCertificate(null);
        setModalMode(null);
      });
  };

  const handleOnActiveChange = (row) => {
    setSelectedCertificate(row);
    setModalMode('active');
  };

  const handleOnActiveConfirm = () => {
    changeActiveService({ id: selectedCertificate.id })
      .then(() => {
        getCertificates();
        toast.success('Estatus cambiado');
      })
      .catch((error) => {
        toast.error('Error, no se pudo cambiar el estatus');
      })
      .finally(() => {
        setSelectedCertificate(null);
        setModalMode(null);
      });
  };

  const handleOnChangeImage = (row) => {
    setSelectedCertificate(row);
    setModalMode('logo');
  };

  const handleOnCloseCertificateLogoModal = () => {
    setSelectedCertificate(null);
    setModalMode(null);
  };

  const handleOnSaveCertificateLogoModal = (files) => {
    const data = {
      file: files[0],
      id: selectedCertificate.id
    };

    saveCertificateLogoService(data).then((response) => {
      getCertificates();
      toast.success('Image saved');
    });
    setModalMode(false);
  };

  return (
    <>
      <CertificatesView
        loading={loading}
        certificates={certificates}
        onFilterCertificates={handleOnFilterCertificates}
        onAddCertificate={handleOnAddCertificate}
        onEditCertificate={handleOnEditCertificate}
        onChangeImage={handleOnChangeImage}
        onDeleteCertificate={handleOnDeleteCertificate}
        onActiveCertificate={handleOnActiveChange}
        onActiveUser={handleOnActiveChange}
      />
      {/*{selectedCertificate &&
      (modalMode === 'Certificate' || modalMode === 'password') && (
      */}
      {selectedCertificate && modalMode === 'certificate' && (
        <CertificateModal
          CertificateData={selectedCertificate}
          modalMode={modalMode}
          countries={countries}
          //roles={roles}

          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedCertificate && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Certificado"
        >
          {'¿Estas seguro que quieres borrar el certificado ' +
            selectedCertificate.email +
            '?'}
        </Modal>
      )}

      {selectedCertificate && modalMode === 'active' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnActiveConfirm}
          title={
            selectedCertificate?.active === 0
              ? 'Activar certificado?'
              : 'Desactivar certificado'
          }
        >
          {'¿Estas seguro que quieres ' +
            (selectedCertificate?.active === 0 ? 'activar' : 'desactivar') +
            ' el certificado ' +
            selectedCertificate.email +
            '?'}
        </Modal>
      )}

      {selectedCertificate && modalMode === 'logo' && (
        <DropFile
          open={true}
          title={'Subir logo del certificado'}
          url={selectedCertificate?.logo}
          onConfirm={handleOnSaveCertificateLogoModal}
          onClose={handleOnCloseCertificateLogoModal}
          confirmText="Enviar"
          cancelText="Cancelar"
        ></DropFile>
      )}
    </>
  );
};

export default CertificatesComponent;
