/** Import react/libraries section **/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

/** Import helpers section **/
import { PRIVATE_ROUTES } from '../../config/ROUTES_CONTENT';

/** Import styles section **/
import './PrivateContentStyles.scss';

/** Import resources section **/

/** Import component section **/
import { RouteAccess } from '../../access/AccessControl';

const PrivateContentComponent = () => {
	return (
		<Switch>
			<>
				{PRIVATE_ROUTES.map((route, index) => (
					<RouteAccess key={index} permissionsRequest={route?.path}>
						<Route
							exact
							path={route.path}
							component={route.component}
						/>
					</RouteAccess>
				))}
			</>
			<Redirect to="/" />
		</Switch>
	);
};

export default PrivateContentComponent;
