import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import * as colors from './colors';

const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        height: '40px',
        minWidth: '100px',
        color: 'white',
        fontSize: '10px',
        textTransform: 'uppercase',
        borderRadius: '6px',
        backgroundColor: colors.color_secondary,
        '&:hover': {
          backgroundColor: colors.color_secondary
        },
        '&:disabled': {
          color: colors.color_cancel,
          backgroundColor: colors.color_disabled
        }
      },
      text: {
        padding: '0 15px'
      }
    },
    MuiCard: {
      root: {
        borderRadius: 12
      }
    },
    MuiPaper: {
      root: {
        borderRadius: 12
      }
    }
  },
  status: {
    danger: colors.color_delete
  },
  palette: {
    primary: {
      main: colors.color_primary
    },
    secondary: {
      main: colors.color_secondary
    },
    light: colors.color_light,
    complementary: {
      main: colors.color_complementary
    },
    complementary2: {
      main: colors.color_complementary_2
    },
    complementary3: {
      main: colors.color_complementary_3
    },
    drawerBackground: {
      main: colors.color_secondary
    },
    success: {
      light: colors.color_check_light,
      main: colors.color_check
    },
    error: {
      main: colors.color_delete
    },
    dark: colors.color_dark,
    normal: colors.color_normal
  },
  typography: {
    fontFamily: [
      'Gotham-Book',
      'Open Sans',
      'Tahoma',
      'Segoe UI',
      'sans-serif'
    ].join(','),
    fontSize: 11,
    // fontWeight: 200,
    body2: {
      // fontWeight: 600,
    },
    h3: {
      fontSize: 22,
      fontWeight: 'bolder'
    },
    h4: {
      textTransform: 'uppercase',
      fontSize: 12,
      color: colors.color_normal
    },
    h5: {
      fontSize: 16
    }
  }
});

export default theme;
