import request from '../api-connector';

export const getCountriesService = () => {
	const serviceDef = {
		url: 'countries',
		method: 'get',
		params: null
	};
	return request(serviceDef);
};
