/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

/** Import syles section **/
import './UserModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextField from '../../../components/inputs/MUITextField';
import MUISelect from '../../../components/inputs/MUISelect';

const UserModalView = ({
	user,
	modalMode,
	roles,
	onInputChange,
	onSwitchChange,
	onClose,
	onSave,
	disabled
}) => {
	const title =
		modalMode === 'password'
			? 'Cambiar contraseña'
			: user?.id
			? 'Editar usuario'
			: 'Agregar usuario';
	return (
		<ModalComponent
			open={true}
			title={title}
			element={user}
			onClose={onClose}
			onConfirm={onSave}
			disabled={disabled}
		>
			<div className='usermodal'>
				{modalMode === 'user' && (
					<>
						<FormControlLabel
							className='usermodal_active'
							control={
								<Switch
									label='Activo'
									checked={user?.active === 1}
									onChange={(event) => onSwitchChange(event)}
									name='active'
									color='primary'
									inputProps={{
										'aria-label': 'secondary checkbox'
									}}
								/>
							}
							label='Activo'
						/>
						<div className='usermodal_row'>
							<MUITextField
								label='Nombre'
								defaultValue={user?.first_name}
								type='text'
								name='first_name'
								onChange={(data) =>
									onInputChange(data, 'first_name')
								}
							/>
							<MUITextField
								label='Apellido'
								defaultValue={user?.last_name}
								type='text'
								name='last_name'
								onChange={(data) =>
									onInputChange(data, 'last_name')
								}
							/>
						</div>
						<div className='usermodal_row'>
							<MUITextField
								label='Email'
								defaultValue={user?.email}
								type='text'
								name='email'
								onChange={(data) =>
									onInputChange(data, 'email')
								}
							/>

							<MUISelect
								label='Rol'
								defaultValue={user?.role_id}
								items={roles}
								idSelector='id'
								selector='title'
								noSelectedLabel='Select'
								name='role_id'
								onChange={(data) =>
									onInputChange(data, 'role_id')
								}
								className='usermodal_rolTitle'
							/>
						</div>
					</>
				)}

				{(modalMode === 'password' ||
					(modalMode === 'user' && !user?.id)) && (
					<div className='usermodal_row'>
						<MUITextField
							label='Contraseña'
							defaultValue={user?.password}
							type='password'
							name='password'
							error={user?.password !== user?.password_conf}
							onChange={(data) => onInputChange(data, 'password')}
						/>

						<MUITextField
							label='Confirmar contraseña'
							defaultValue={user?.password_conf}
							type='password'
							name='password_conf'
							error={user?.password !== user?.password_conf}
							onChange={(data) =>
								onInputChange(data, 'password_conf')
							}
						/>
					</div>
				)}
			</div>
		</ModalComponent>
	);
};

export default UserModalView;
