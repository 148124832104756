// System colors
export const color_primary = '#6d6253';
export const color_secondary = '#151515';
export const color_complementary = '#decb40';
export const color_complementary_2 = '#ced6de';
export const color_complementary_3 = '#687d95';
export const color_complementary_4 = '#475b7a';
export const color_light = '#f0f0f0';
export const color_white = '#ffffff';
export const color_dark = '#28292b';
export const color_medium = '#adadad';
export const color_normal = '#707070';
export const color_disabled = '#e4e4e4';
export const color_cancel = '#adadad';
export const color_delete = '#fa6347';
export const color_check = '#3bbb3e';
export const color_check_light = '#8ad48c';
