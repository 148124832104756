import request from '../api-connector';

export const getCertificatesService = () => {
	const serviceDef = {
		url: 'certificates/list',
		method: 'get',
		params: null
	};
	return request(serviceDef);
};

export const getCertificateByIdService = ({ id }) => {
	const serviceDef = {
		url: 'certificates/read',
		method: 'get',
		params: { id }
	};
	return request(serviceDef);
};

export const addCertificateService = ({
	name,
	certification,
	email,
	active,
	address,
	phone,
	location,
	country_id,
	company,
	logo,
	instagram,
	facebook
}) => {
	const serviceDef = {
		url: 'certificates/create',
		method: 'post',
		params: {
			name,
			certification,
			email,
			active,
			address,
			phone,
			location,
			country_id,
			company,
			logo,
			instagram,
			facebook
		}
	};
	return request(serviceDef);
};

export const updateCertificateService = ({
	id,
	name,
	certification,
	email,
	active,
	address,
	phone,
	location,
	country_id,
	company,
	logo,
	instagram,
	facebook
}) => {
	const serviceDef = {
		url: 'certificates/update',
		method: 'put',
		params: {
			id,
			name,
			certification,
			email,
			active,
			address,
			phone,
			location,
			country_id,
			company,
			logo,
			instagram,
			facebook
		}
	};
	return request(serviceDef);
};

export const changeActiveService = ({ id }) => {
	const serviceDef = {
		url: 'certificates/update/active',
		method: 'put',
		params: {
			id
		}
	};
	return request(serviceDef);
};

export const deleteCertificateService = ({ id }) => {
	const serviceDef = {
		url: 'certificates/delete',
		method: 'delete',
		params: { id }
	};
	return request(serviceDef);
};

export const saveCertificateLogoService = ({ file, id }) => {
	const dataForm = new FormData();
	dataForm.append('file', file);
	dataForm.append('id', id);

	const serviceDef = {
		url: 'certificates/upload-logo',
		method: 'post',
		params: dataForm
	};
	return request(serviceDef);
};
