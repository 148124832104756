import * as routes from '../../config/URL_ROUTES';

/** Import resources section **/
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import LockIcon from '@material-ui/icons/Lock';
import CardMembershipIcon from '@material-ui/icons/CardMembership';

/*********************************************************************
 ************************  MENU ITEMS IDS  ***************************
 *********************************************************************/
const USER_MENU = 'users';
const CONFIGURATION_MENU = 'configuration';
const PERMISSIONS_MENU = 'permissions';
const PERMISSIONS_CATALOGUE_MENU = 'permissions-catalogue';
const CERTIFICATES_MENU = 'certificates';

/*********************************************************************
 **************************  MENU ITEMS  *****************************
 *********************************************************************/

const MENU_ITEMS = [
	{
		id: USER_MENU,
		label: 'Usuarios',
		url: routes.USERS_ROUTE,
		icon: PersonIcon
	},
	{
		id: CERTIFICATES_MENU,
		label: 'Certificados',
		url: routes.CERTIFICATES_ROUTE,
		icon: CardMembershipIcon
	},
	{
		id: CONFIGURATION_MENU,
		label: 'Configuración',
		icon: SettingsIcon,
		items: [
			{
				id: PERMISSIONS_MENU,
				label: 'Permisos por rol',
				url: routes.ROLE_PERMISSIONS_ROUTE,
				icon: AccountTreeIcon
			},
			{
				id: PERMISSIONS_CATALOGUE_MENU,
				label: 'Permisos',
				url: routes.PERMISSIONS_ROUTE,
				icon: LockIcon
			}
		]
	}
];
export default MENU_ITEMS;
