/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import resources section **/
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel, MenuItem, Select } from '@material-ui/core';

/** Styles **/
const useStyles = makeStyles(() => ({
	root: {
		backgroundColor: 'transparent',
		marginLeft: '5px',
		marginRight: '5px',
		margin: '0px',
		minWidth: '120px',
		flexGrow: '1'
	},
	label: {
		marginBottom: '5px'
	}
}));

const styles = () => ({
	inputLabel: {
		fontSize: '12px',
		height: '12px'
	},
	select: {
		margin: 0,
		verticalAlign: 'center',
		backgroundColor: '#fff',

		placeholderTextColor: '#ced4da',
		padding: '0',
		fontSize: '12px'
	},
	menuItem: { fontSize: '12px' }
});

export const MUISelect = ({
	id,
	label = '',
	name,
	labelId,
	defaultValue,
	items,
	idSelector,
	selector,
	noSelectedLabel = 'Seleccionar',
	onChange,
	readOnly = false,
	disabled = false,
	...otherProps
}) => {
	const classes = useStyles();
	const customClasses = styles();
	const inputLabelId = labelId !== null ? labelId : label + '__InputLabel';

	const [value, setValue] = useState(-1);

	useEffect(() => {
		const newValue = defaultValue ? defaultValue : -1;
		setValue(newValue);
	}, [defaultValue, items]);

	const handleOnSelect = (e) => {
		const newValue = e.target.value;
		const returnedValue = newValue !== -1 ? newValue : null;
		setValue(newValue);
		onChange({ id: id, name: name, value: returnedValue });
	};

	return (
		<div id={'MUI_' + id} className={classes.root}>
			<InputLabel id={inputLabelId} style={customClasses.inputLabel}>
				{label}
			</InputLabel>
			<Select
				labelId={inputLabelId}
				id={id}
				value={value}
				onChange={handleOnSelect}
				style={customClasses.select}
				disabled={disabled}
				fullWidth
				inputProps={{ readOnly: readOnly }}
				{...otherProps}
			>
				<MenuItem value={-1} style={customClasses.menuItem}>
					<em>{noSelectedLabel}</em>
				</MenuItem>

				{items?.map((item, index) => (
					<MenuItem
						data-cy='menu-item'
						key={index}
						value={item[idSelector]}
						style={customClasses.menuItem}
					>
						{item[selector]}
					</MenuItem>
				))}
			</Select>
		</div>
	);
};

export default MUISelect;
