/** Import react/libraries section **/
import React, { useMemo } from 'react';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ImageIcon from '@material-ui/icons/Image';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';

/** Import helpers section **/
import './CertificatesStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import DataTable from '../../components/DataTable';
import ActiveCell from '../../components/ActiveCell';

const CertificatesView = ({
  loading,
  certificates,
  onFilterCertificates,
  onAddCertificate,
  onChangeImage,
  onEditCertificate,
  onDeleteCertificate,
  onActiveCertificate,
  onActiveUser
}) => {
  // const ActiveCell = ({ row }) => {
  //   if (row.active)
  //     return (
  //       <CheckCircleOutlineIcon
  //         className="g-activeicon active Certificates_active"
  //         onClick={() => onActiveCertificate(row)}
  //       />
  //     );
  //   return (
  //     <NotInterestedIcon
  //       className="g-activeicon Certificates_active"
  //       onClick={() => onActiveCertificate(row)}
  //     />
  //   );
  // };

  const ActionsCell = ({ row }) => {
    return (
      <>
        {/* <LockIcon
          className="g-row-action-button"
          onClick={() => onChangePassword(row)}
       /> */}
        <EditIcon
          className="g-row-action-button"
          onClick={() => onEditCertificate(row)}
        />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => onDeleteCertificate(row)}
        />
      </>
    );
  };

  const LogoCell = ({ row }) => {
    if (row.logo)
      return (
        <ImageIcon
          className="g-activeicon hasImage users_active"
          onClick={() => onChangeImage(row)}
        />
      );
    return (
      <ImageOutlinedIcon
        className="g-activeicon users_active"
        onClick={() => onChangeImage(row)}
      />
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Nombre',
        selector: 'name',
        sortable: true,
        center: true,
        wrap: true,
        grow: 2
      },
      {
        name: 'Cetificado',
        selector: 'certification',
        sortable: true,
        center: true
      },
      {
        name: 'email',
        selector: 'email',
        sortable: true,
        center: true,
        grow: 3
      },
      {
        name: 'Dirección',
        selector: 'address',
        sortable: true,
        center: true,
        wrap: true,
        grow: 2
      },
      {
        name: 'Ubicación',
        selector: 'location',
        sortable: true,
        center: true
      },
      {
        name: 'Pais',
        selector: (row) => row?.countries?.name_es,
        sortable: true,
        center: true,
        wrap: true,
        grow: 2
      },
      {
        name: 'Teléfono',
        selector: 'phone',
        sortable: true,
        center: true
      },
      {
        name: 'Compañía',
        selector: 'company',
        sortable: true,
        center: true
      },
      {
        name: 'Logo',
        selector: 'logo',
        cell: (row) => <LogoCell row={row} />,
        sortable: true,
        center: true
      },
      {
        name: 'Instagram',
        selector: 'instagram',
        sortable: true,
        center: true
      },
      {
        name: 'Facebook',
        selector: 'facebook',
        sortable: true,
        center: true
      },
      {
        name: 'Activo',
        selector: 'active',
        cell: (row) => (
          <ActiveCell
            active={row?.active ? true : false}
            onSwitchActive={(e) => onActiveCertificate(row)}
          />
        ),
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    [onActiveCertificate]
  );

  return (
    <>
      <PageTitle title="Certificados" />
      <Paper className="g-page-header certificates_header">
        <div className="certificates_header_filterby">
          <FilterBy onChange={onFilterCertificates} />
        </div>
        <Button onClick={onAddCertificate} startIcon={<AddCircleIcon />}>
          Agregar nuevo Certificado
        </Button>
      </Paper>

      <DataTable data={certificates} columns={tableColumns} loading={loading} />
    </>
  );
};

export default CertificatesView;
