/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/** Import helpers section **/
import {
  addCertificateService,
  updateCertificateService
  //  changePasswordService
} from '../../../api/services/certificates-services';

import { isValidEmail } from '../../../utils/validations';

/** Import component section **/
import CertificateModalView from './CertificateModalView';

const CertificateModalComponent = ({
  CertificateData,
  modalMode,
  countries,
  onClose,
  onUpdateList
}) => {
  const [certificate, setCertificate] = useState(null);
  const [dataComplete, setDataComplete] = useState(false);

  useEffect(() => {
    const newCertificate = Object.assign({}, CertificateData);
    setCertificate(newCertificate);
  }, [CertificateData]);

  const handleOnInputChange = (data, field) => {
    const { name, value } = data;
    setCertificate((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    if (!certificate) return;
    let validated = true;
    const email = certificate?.email?.trim();
    const name = certificate?.name?.trim();
    const certification = certificate?.certification?.toString()?.trim();
    const phone = certificate?.phone?.toString()?.trim();
    if (
      !name ||
      name === '' ||
      !certification ||
      certification === '' ||
      !phone ||
      phone === '' ||
      (email && email !== '' && !isValidEmail(email))
    ) {
      validated = false;
    }

    setDataComplete(validated);
  }, [certificate, modalMode]);

  const handleOnSave = () => {
    if (certificate.id) {
      updateCertificate();
    } else {
      createCertificate();
    }
  };

  const createCertificate = () => {
    addCertificateService(certificate)
      .then((response) => {
        toast('Certificado creado', { type: 'success' });
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast('Error de creación de Certificado', { type: 'warning' });
      });
  };

  const updateCertificate = () => {
    console.log('updateCertificate ', certificate);

    updateCertificateService(certificate)
      .then(() => {
        toast('Certificado actualizado', { type: 'success' });
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast('Error de actualización de Certificado', { type: 'warning' });
      });
  };
  return (
    <CertificateModalView
      certificate={certificate}
      modalMode={modalMode}
      countries={countries}
      onInputChange={handleOnInputChange}
      onClose={onClose}
      onSave={handleOnSave}
      disabled={!dataComplete}
    />
  );
};

export default CertificateModalComponent;
