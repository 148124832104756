/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/

/** Import syles section **/
import './PermissionsModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../components/Modal';
import MUITextField from '../../../components/inputs/MUITextField';
import MUISelect from '../../../components/inputs/MUISelect';

const typesNames = [
	{ id: 1, name: 'Routes' },
	{ id: 2, name: 'Menu' },
	{ id: 3, name: 'Actions' }
];

const permissionModalView = ({
	permission,
	modalMode,
	onInputChange,
	onClose,
	onSave,
	disabled
}) => {
	const title = permission?.id ? 'Editar Permiso' : 'Agregar Permiso';
	return (
		<ModalComponent
			open={true}
			title={title}
			element={permission}
			onClose={onClose}
			onConfirm={onSave}
			disabled={disabled}
		>
			<div className="permissionModal">
				{modalMode === 'permission' && (
					<div className="permissionModal_row">
						<MUITextField
							label="Acción"
							defaultValue={permission?.code}
							type="text"
							name="code"
							onChange={(data) => onInputChange(data, 'code')}
						/>
						<MUISelect
							className="permissionModal_row_select"
							label="Tipo"
							defaultValue={permission?.type}
							items={typesNames}
							idSelector="id"
							selector="name"
							noSelectedLabel="Select"
							name="type"
							onChange={(data) => onInputChange(data, 'type')}
						/>
					</div>
				)}
			</div>
		</ModalComponent>
	);
};

export default permissionModalView;
